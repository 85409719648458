header {
    background-color: #add8e6;
    box-shadow: 0 1px 4px #888888;
    .brand {
        height: 60px;
        padding: 4px;
        background: #ffffff;
        border-radius: 20px;
    }
    .navbar-nav {
        .nav-item {
            padding: 0 1rem;
            margin: 0 .25rem;
            background-color: #ffffff;
            border-radius: 20px;
            border: 1px solid transparent;
            .nav-link {
                color: #222222;
            }
            &:hover {
                background-color: #add8e6;
                border: 1px solid #ffffff;
            }
        }
    }
}
@media screen and (max-width: 600px) {
    header {
        .navbar-nav {
            .nav-item {
                text-align: center;
                margin-top: 0.25rem;
            }
        }
    }
  }